import React from 'react'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Index from '../../../../content/accordion/sluzby'

import { graphql } from 'gatsby'
import {
  SingleAccordion,
  Description,
} from '../../../components/Services/ServiceOverview/SingleAccordion'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components'

export const Mzdy = ({ data: { page }, pageContext }) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={frontmatter.title} />
        <div className="Paragraph--Wrapper container">
          <p>
            Vedení personální agendy je nejen administrativně nesmírně náročné,
            ale nese v sobě riziko, že se mezi zaměstnanci roznese výše platů –
            a i z tohoto důvodu se vyplácí mzdové účetnictví a evidenci
            outsourcovat. Mzdovou agendu vedeme maximálně diskrétně, spolehlivě
            a dodržujeme termíny. Stále sledujeme vývoj legislativy, abyste měli
            jistotu, že mzdy zpracováváme dle nejnovějších předpisů.
          </p>
          <p>
            Mzdy jsou specifickým a zároveň legislativně náročným účetním
            oborem. Naše účetní mají zkušenosti s vedením mzdové agendy, díky
            čemuž máte zaručenou správnost a naprostou diskrétnost zpracování.
            Zároveň převezmeme odpovědnost za rizika správních deliktů v mzdové
            oblasti. Na základě plné moci Vás zastoupíme při jednání s úřady a
            institucemi.
          </p>
          <SingleAccordion
            items={Index.mzdy.links.map(item => ({
              title: item.name,
              description: <Description {...item} />,
            }))}
          />
        </div>
      </Layout>
    </>
  )
}
export default Mzdy

export const pageQuery = graphql`
  query Mzdy {
    page: markdownRemark(frontmatter: { slug: { eq: "sluzby/mzdy" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
